<template>
  <div class="article-box" v-loading="loading">
    <!-- <div class="pag-title clearfix">
      <div class="page-title-name pull-left"></div>
    </div> -->
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="基础资料" name="first">
        <div style="padding: 10px">
          <el-descriptions class="margin-top" title="" :column="3" border>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">姓名</template>{{ detailInfo.nickName || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">出生年月</template>{{ detailInfo.birthday || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">性别</template>{{ sexArr[detailInfo.sex] || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">民族</template>{{ detailInfo.national || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">身份证号</template>{{ detailInfo.idCard || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">手机</template>{{ detailInfo.mobile || "-" }}
              <el-button type="text" @click="sureMobile">修改</el-button>
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions class="margin-top" title="" :column="3" border v-if="isShow">
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">国籍</template>{{ detailInfo.nationality || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">籍贯</template>{{ detailInfo.nativePlace || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">学位</template>{{ detailInfo.schoolRecord || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :span="3">
              <template slot="label">毕业院校</template>{{ detailInfo.graduateSchool || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">政治面貌</template>{{ detailInfo.politicalLandscape || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">学历</template>{{ detailInfo.learningExperience || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">工作单位</template>{{ detailInfo.unitName || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">职务</template>{{ detailInfo.position || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">技术职称</template>{{ detailInfo.technicalTitles || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">专业专长</template>{{ detailInfo.professional || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">在职/离退休</template>{{ detailInfo.workingState || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">电子邮箱</template>{{ detailInfo.email || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">邮政编码</template>{{ detailInfo.zipCode || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :span="2">
              <template slot="label">通讯地址</template>{{ detailInfo.mailingAddress || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :span="2">
              <template slot="label">是否院士</template>{{ academicianArr[detailInfo.isAcademician] || "-" }}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top" title="" :column="3" border v-else>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">国籍</template>
              <el-input v-model="detailInfo.nationality" placeholder="请输入国籍"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">籍贯</template>
              <el-input v-model="detailInfo.nativePlace" placeholder="请输入籍贯"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">学位</template>
              <el-input v-model="detailInfo.schoolRecord" placeholder="请输入学位"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :span="3">
              <template slot="label">毕业院校</template>
              <el-input v-model="detailInfo.graduateSchool" placeholder="请输入毕业院校"></el-input>
            </el-descriptions-item>

            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">政治面貌</template>
              <el-input v-model="detailInfo.politicalLandscape" placeholder="请输入政治面貌"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">学历</template>
              <el-input v-model="detailInfo.learningExperience" placeholder="请输入学历"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">工作单位</template>
              <el-input v-model="detailInfo.unitName" placeholder="请输入工作单位"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">职务</template>
              <el-input v-model="detailInfo.position" placeholder="请输入职务"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">技术职称</template>
              <el-input v-model="detailInfo.technicalTitles" placeholder="请输入技术职称"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">专业专长</template>
              <el-input v-model="detailInfo.professional" placeholder="请输入专业专长"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">在职/离退休</template>
              <el-input v-model="detailInfo.workingState" placeholder="请输入在职/离退休"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">电子邮箱</template>
              <el-input v-model="detailInfo.email" placeholder="请输入电子邮箱"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">邮政编码</template>
              <el-input v-model="detailInfo.zipCode" placeholder="请输入邮政编码"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :span="2">
              <template slot="label">通讯地址</template>
              <el-input v-model="detailInfo.mailingAddress" placeholder="请输入通讯地址"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :span="2">
              <template slot="label">是否院士</template>
              <el-radio v-model="radio" label="1">中科院</el-radio>
              <el-radio v-model="radio" label="2">工程院</el-radio>
              <el-radio v-model="radio" label="3">其他国家</el-radio>
              <el-radio v-model="radio" label="4">否</el-radio>
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top" title="" :column="3" border>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :span="2">
              <template slot="label">活动参与分支机构</template>{{ detailInfo.partake.join(",") || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :span="3">
              <template slot="label">关注分支机构</template>{{ detailInfo.follow.join(",") || "-" }}
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :span="3">
              <template slot="label">照片</template>
              <UploadImage :config="uploadImageConfig" @UploadImageFun="UploadImageFun"></UploadImage>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :span="3">
              <template slot="label">主要工作成果、业绩</template>{{ detailInfo.workResult || "-" }}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top" title="个人简历" :column="3" border v-if="detailInfo.memberForResumes">
            <template v-for="(item, index) in detailInfo.memberForResumes">
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :key="'time' + index">
                <template slot="label">起止年月</template>{{ item.time || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :key="'unit' + index">
                <template slot="label">单位</template>{{ item.unit || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'position' + index">
                <template slot="label">职务</template>{{ item.position || "-" }}
              </el-descriptions-item>
            </template>
            <el-descriptions-item label-class-name="no-my-label" content-class-name="my-content"
              v-if="!detailInfo.memberForResumes.length">
              暂无数据
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top" title="论文" :column="4" border v-if="detailInfo.memberForPapers">
            <template v-for="(item, index) in detailInfo.memberForPapers">
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :key="'title' + index">
                <template slot="label">论文题目</template>{{ item.title || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'journalName' + index">
                <template slot="label">期刊名称</template>{{ item.journalName || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'volumeIssue' + index">
                <template slot="label">年卷期号</template>{{ item.volumeIssue || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'ranking' + index">
                <template slot="label">本人排名</template>{{ item.ranking || "-" }}
              </el-descriptions-item>
            </template>
            <el-descriptions-item label-class-name="no-my-label" content-class-name="my-content"
              v-if="!detailInfo.memberForPapers.length">
              暂无数据
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top" title="著作" :column="4" border v-if="detailInfo.memberForBooks">
            <template v-for="(item, index) in detailInfo.memberForBooks">
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'bookName ' + index">
                <template slot="label">著作名称</template>{{ item.bookName || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'publicationDate ' + index">
                <template slot="label">出版时间</template>{{ item.publicationDate || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :key="'press ' + index">
                <template slot="label">出版社</template>{{ item.press || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'ranking2 ' + index">
                <template slot="label">本人排名</template>{{ item.ranking || "-" }}
              </el-descriptions-item>
            </template>
            <el-descriptions-item label-class-name="no-my-label" content-class-name="my-content"
              v-if="!detailInfo.memberForBooks.length">
              暂无数据
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top" title="专利" :column="5" border v-if="detailInfo.memberForAccredits">
            <template v-for="(item, index) in detailInfo.memberForAccredits">
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'category' + index">
                <template slot="label">类别</template>{{ item.category || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'patentName' + index">
                <template slot="label">专利名称</template>{{ item.patentName || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'patentNo' + index">
                <template slot="label">专利号</template>{{ item.patentNo || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'accreditDate' + index">
                <template slot="label">授奖时间</template>{{ item.accreditDate || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'ranking3' + index">
                <template slot="label">本人排名</template>{{ item.ranking || "-" }}
              </el-descriptions-item>
            </template>
            <el-descriptions-item label-class-name="no-my-label" content-class-name="my-content"
              v-if="!detailInfo.memberForAccredits.length">
              暂无数据
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top" title="主要奖励、荣誉" :column="4" border v-if="detailInfo.memberForRewards">
            <template v-for="(item, index) in detailInfo.memberForRewards">
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'rewardName' + index">
                <template slot="label">奖励、荣誉名称</template>{{ item.rewardName || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'prizeUnit' + index">
                <template slot="label">授奖单位</template>{{ item.prizeUnit || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :key="'time2' + index">
                <template slot="label">获奖时间</template>{{ item.time || "-" }}
              </el-descriptions-item>
              <el-descriptions-item label-class-name="my-label" content-class-name="my-content"
                :key="'ranking5' + index">
                <template slot="label">本人排名</template>{{ item.ranking || "-" }}
              </el-descriptions-item>
            </template>
            <el-descriptions-item label-class-name="no-my-label" content-class-name="my-content"
              v-if="!detailInfo.memberForRewards.length">
              暂无数据
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top" title="附件" :column="1" border>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">工作成果、业绩证明材料</template>
              <div class="file-show-list">
                <div v-for="f in detailInfo.resultProveInfos" :key="f.id" class="file-show-item">
                  <i class="el-icon-document" style="
                      vertical-align: middle;
                      margin-right: 10px;
                      font-size: 18px;
                    "></i>
                  <span class="li-text">{{ f.remark }}</span>
                  <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                </div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">论文证明材料</template>
              <div class="file-show-list">
                <div v-for="f in detailInfo.paperProveInfos" :key="f.id" class="file-show-item">
                  <i class="el-icon-document" style="
                      vertical-align: middle;
                      margin-right: 10px;
                      font-size: 18px;
                    "></i>
                  <span class="li-text">{{ f.remark }}</span>
                  <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                </div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">著作证明材料</template>
              <div class="file-show-list">
                <div v-for="f in detailInfo.bookProveInfos" :key="f.id" class="file-show-item">
                  <i class="el-icon-document" style="
                      vertical-align: middle;
                      margin-right: 10px;
                      font-size: 18px;
                    "></i>
                  <span class="li-text">{{ f.remark }}</span>
                  <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                </div>
              </div>
              <!-- <div class="file-down-item" v-for="(f,i) in detailInfo.bookProveInfos" :key="'file'+i" @click="download(f.path, f.fileName)">{{f.fileName}}</div> -->
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">专利证明材料</template>
              <div class="file-show-list">
                <div v-for="f in detailInfo.patentProveInfos" :key="f.id" class="file-show-item">
                  <i class="el-icon-document" style="
                      vertical-align: middle;
                      margin-right: 10px;
                      font-size: 18px;
                    "></i>
                  <span class="li-text">{{ f.remark }}</span>
                  <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                </div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">荣誉、奖励证明材料</template>
              <div class="file-show-list">
                <div v-for="f in detailInfo.rewardProveInfos" :key="f.id" class="file-show-item">
                  <i class="el-icon-document" style="
                      vertical-align: middle;
                      margin-right: 10px;
                      font-size: 18px;
                    "></i>
                  <span class="li-text">{{ f.remark }}</span>
                  <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                </div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">签字及加盖公章后的pdf文件</template>
              <div class="file-show-list">
                <div v-for="f in detailInfo.pdfInfo" :key="f.id" class="file-show-item">
                  <i class="el-icon-document" style="
                      vertical-align: middle;
                      margin-right: 10px;
                      font-size: 18px;
                    "></i>
                  <span class="li-text">{{ f.remark }}</span>
                  <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                </div>
              </div>
            </el-descriptions-item>

            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">身份证正面pdf文件</template>
              <div class="file-show-list">
                <div v-for="f in detailInfo.idCardFrs" :key="f.id" class="file-show-item">
                  <i class="el-icon-document" style="
                      vertical-align: middle;
                      margin-right: 10px;
                      font-size: 18px;
                    "></i>
                  <span class="li-text">{{ f.remark }}</span>
                  <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                </div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">身份证国徽面pdf文件</template>
              <div class="file-show-list">
                <div v-for="f in detailInfo.idCardBks" :key="f.id" class="file-show-item">
                  <i class="el-icon-document" style="
                      vertical-align: middle;
                      margin-right: 10px;
                      font-size: 18px;
                    "></i>
                  <span class="li-text">{{ f.remark }}</span>
                  <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                </div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">职称证书pdf文件</template>
              <div class="file-show-list">
                <div v-for="f in detailInfo.degreeFiles" :key="f.id" class="file-show-item">
                  <i class="el-icon-document" style="
                      vertical-align: middle;
                      margin-right: 10px;
                      font-size: 18px;
                    "></i>
                  <span class="li-text">{{ f.remark }}</span>
                  <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                </div>
              </div>
            </el-descriptions-item>


          </el-descriptions>
          <el-button type="primary" @click="clickAdd(1)" v-if="isShow">修改</el-button>
          <el-button type="primary" @click="clickAdd(2)" v-else>保存</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="章程文件" name="second">
        <!-- <h3>章程文件</h3> -->
        <div v-if="this.attFileList.length != 0">
          <FileEcho :fileList="attFileList"></FileEcho>
        </div>
        <div v-else class="elseBut"></div>
      </el-tab-pane>
    </el-tabs>

    <!--  -->

    <el-dialog title="修改手机号" :visible="show1" width="450px" :close-on-click-modal="false" :show-close="true"
      @close="AmendClearss">
      <el-form status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" :model="ruleForm">
        <el-form-item label="新手机号：" prop="mobile">
          <el-input style="width: 100%" v-model="ruleForm.mobile" placeholder="请输入新手机号"></el-input>
        </el-form-item>
        <el-form-item label="验证码：" prop="username">
          <div class="pr">
            <el-input placeholder="请输入短信验证码" v-model="ruleForm.username" style="width: 100%" @blur="inputUser">
            </el-input>
            <button @click.prevent="getShortMessageCode()" class="code-btn" :disabled="!show">
              <span v-show="show">获取验证码</span>
              <span v-show="!show" class="count">{{ count }} s</span>
            </button>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain @click="AmendClearss">取消</el-button>
        <el-button type="primary" size="medium" @click.native.prevent="AmendSubmits">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <el-dialog title="工作简历" :visible="show" width="600px" :close-on-click-modal="false" :show-close="true"
      @close="resumeClear1">
      111111111111111111111111111111111111
    </el-dialog> -->
  </div>
</template>
<script>
import {
  personPprofile,
  personPup,
  InstitutionModuleGet,
} from "@/http/api/InstitutionApi";
import { FileDownload } from "@/http/api/FileApi";
import { IndexStore } from "@/store/module/index";
import { valCodeCreate, userUupdate, valCodeValid } from "@/http/api/LoginApi";
import UploadImage from "@c/upload/UploadImage.vue";
import FileEcho from "@c/upload/FileEcho.vue";

export default {
  components: { UploadImage, FileEcho },

  data () {
    return {
      show: false,
      attFileList: [],
      activeName: "first",
      ruleForm: {
        mobile: "",
        username: "",
      },
      uploadImageConfig: {
        limit: 1,
        callback: "",
      },
      rules: {
        mobile: [
          { required: true, message: "请输入新的手机号", trigger: "blur" },
          {
            pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
            message: "手机号格式有误",
            trigger: "blur",
          },
        ],
        username: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            pattern: /^\d{1}$/,
            message: "验证码格式错误",
          },
        ],
      },
      codebtn: true,
      show: true,
      timer: 0,
      count: 0,
      show1: false,
      radio: 0,
      isShow: true,
      uid: "",
      loading: false,
      sexArr: ["", "男", "女"],
      academicianArr: ["", "中科院", "工程院", "其他国家", "否"],
      detailInfo: {
        memberInstitutions: [], // 分支机构
        memberForResumes: [], // 简历
        memberForPapers: [], // 论文
        memberForBooks: [], // 著作
        memberForAccredits: [], // 专利
        memberForRewards: [], // 奖励荣誉集合 ,
        resultProveInfos: [], // 工作成果、业绩证明材料 附件
        paperProveInfos: [], // 论文 附件
        patentProveInfos: [], // 专利 附件
        bookProveInfos: [], // 著作 附件
        rewardProveInfos: [], // pdf 附件
        follow: [], //关注分支机构
        partake: [], //参与分支机构
        email: "", //string, optional): 邮箱 ,
        id: "", //string, optional): member_person主键ID ,
        learningExperience: "", //string, optional): 学历 ,
        loginName: "", //string, optional): 登录名 ,
        mailingAddress: "", //string, optional): 通讯地址 ,
        politicalLandscape: "", //string, optional): 政治面貌 ,
        position: "", //string, optional): 职务 ,
        professional: "", //string, optional): 专业 ,
        technicalTitles: "", //string, optional): 技术职称 ,
        unitName: "", //string, optional): 单位名称 ,
        workingState: "", //string, optional): 工作状态 :'',//离职，在职，退休) ,
        zipCode: "", //string, optional): 邮编
        pictureFile: "",
      },
      userInfo: IndexStore.userInfo,
    };
  },

  created () {
    this.getpo1Fun();
    this.uid = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    // resumeClear1() {
    //   this.show = false;
    // },
    handleClick (tab, event) {
      console.log(tab);
      if (tab.name == "second") {
        this.getModel();
      }
    },
    getModel () {
      InstitutionModuleGet({ module: 5, type: 2 }).then((res) => {
        if (res.code === "000") {
          console.log(res);
          if (res.result && res.result.length > 0) {
            this.attFileList.push(res.result[0].fileInfo);
          }
        }
      });
    },
    // 获取短信验证码
    getShortMessageCode () {
      //axios请求

      valCodeCreate({ mobile: this.ruleForm.mobile }).then((res) => {
        this.$message.warning("验证码已发送,请注意查收!");
        // 验证码倒计时
        if (!this.timer) {
          console.log(this.timer);
          this.count = 60;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= 60) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      });
    },
    inputUser () {
      console.log(this.ruleForm.username);
      valCodeValid({
        mobile: this.ruleForm.mobile,
        valCode: this.ruleForm.username,
      }).then((res) => {
        console.log(res);
        if (res.result === false) {
          this.$message.warning("请确认验证码输入的是否正确");
        }
      });
    },
    AmendSubmits () {
      console.log(this.userInfo.id, this.userInfo.loginName);
      userUupdate({
        id: this.userInfo.id,
        loginName: this.userInfo.loginName,
        mobile: this.ruleForm.mobile,
      }).then((res) => {
        console.log(res);
      });
    },
    AmendClearss () {
      this.show1 = false;
    },

    sureMobile () {
      this.show1 = true;
    },
    getpo1Fun () {
      const that = this;
      if (!IndexStore.userInfo) {
        setTimeout(() => {
          that.getpo1Fun();
        }, 1000);
      } else {
        this.userInfo = IndexStore.userInfo;
      }
    },
    clickAdd (num) {
      if (num === 1) {
        this.isShow = false;
      } else {
        this.isShow = true;
        const params = {
          nationality: this.detailInfo.nationality,
          nativePlace: this.detailInfo.nativePlace,
          schoolRecord: this.detailInfo.schoolRecord,
          graduateSchool: this.detailInfo.graduateSchool,
          email: this.detailInfo.email, //string, optional): 邮箱 ,
          id: this.detailInfo.id, //string, optional): member_person主键ID ,
          learningExperience: this.detailInfo.learningExperience, //string, optional): 学历 ,
          loginName: this.userInfo.loginName, //string, optional): 登录名 ,
          mailingAddress: this.detailInfo.mailingAddress, //string, optional): 通讯地址 ,
          politicalLandscape: this.detailInfo.politicalLandscape, //string, optional): 政治面貌 ,
          position: this.detailInfo.position, //string, optional): 职务 ,
          professional: this.detailInfo.professional, //string, optional): 专业 ,
          technicalTitles: this.detailInfo.technicalTitles, //string, optional): 技术职称 ,
          unitName: this.detailInfo.unitName, //string, optional): 单位名称 ,
          workingState: this.detailInfo.workingState, //string, optional): 工作状态 :this.detailInfo.politicalLandscape,//离职，在职，退休) ,
          zipCode: this.detailInfo.zipCode, //string, optional): 邮编
        };
        personPup(params).then((res) => {
          if (res.code === "000") {
            this.isShow = true;
            this.getDetail();
            this.$message.success("修改成功！！！");
          }
        });
      }
    },

    getDetail () {
      personPprofile().then((resp) => {
        console.log(resp);
        if (resp.code == "000") {
          this.detailInfo = { ...resp.result };
          let imgArr = [];
          this.detailInfo.pictureInfo.forEach((val) => {
            imgArr.push(val.path);
          });
          this.uploadImageConfig.callback = imgArr.join(",");
          this.detailInfo.memberInstitutions =
            resp.result.memberInstitutions || []; // 分支机构
          this.detailInfo.memberForResumes = resp.result.memberForResumes || []; // 简历
          this.detailInfo.memberForPapers = resp.result.memberForPapers || []; // 论文
          this.detailInfo.memberForBooks = resp.result.memberForBooks || []; // 著作
          this.detailInfo.memberForAccredits =
            resp.result.memberForAccredits || []; // 专利
          this.detailInfo.memberForRewards = resp.result.memberForRewards || []; // 奖励荣誉集合 ,
          this.detailInfo.resultProveInfos = resp.result.resultProveInfos || []; // 工作成果、业绩证明材料 附件
          this.detailInfo.paperProveInfos = resp.result.paperProveInfos || []; // 论文 附件
          this.detailInfo.patentProveInfos = resp.result.patentProveInfos || []; // 专利 附件
          this.detailInfo.bookProveInfos = resp.result.bookProveInfos || []; // 著作 附件
          this.detailInfo.rewardProveInfos = resp.result.rewardProveInfos || []; // 奖励 附件
          this.detailInfo.pdfInfo = resp.result.pdfInfo || []; // pdf 附件
          this.detailInfo.follow = resp.result.memberInstitutions
            .filter((f) => {
              if (f.type == 2) {
                return f;
              }
            })
            .map((s) => {
              return s.nickName;
            });
          this.detailInfo.partake = resp.result.memberInstitutions
            .filter((f) => {
              if (f.type == 1) {
                return f;
              }
            })
            .map((s) => {
              return s.nickName;
            });
        }
      });
    },
    download (path, name) {
      FileDownload(path, name);
    },
    UploadImageFun (val) {
      this.detailInfo.pictureFile = val;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.my-label {
  width: 150px;
}

/deep/.el-descriptions__header {
  margin-top: 20px;
}

/deep/.no-my-label {
  width: 0;
  padding: 0 !important;
  border: none;
}

.file-show-list {
  box-sizing: border-box;
  width: 90%;
  padding: 10px;

  .file-show-item {
    width: 100%;
    padding: 5px 10px;
  }

  .file-show-item:hover {
    background-color: #f7f7f7;
  }

  .downliad-file {
    font-size: 18px;
    vertical-align: middle;
  }

  .downliad-file:hover {
    color: #1e63b0;
  }
}

/* 短信验证码css */
.pr {
  position: relative;
  margin-top: 10px;
}

.code-btn {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 222;
  color: #ef8466;
  font-size: 14px;
  border: none;
  border-left: 1px solid #bababa;
  padding-left: 10px;
  background-color: #fff;
  cursor: pointer;
}

.elseBut {
  width: 100%;
  height: 300px;
  background: url("../../assets/images/nodata.png") no-repeat center center;
}
</style>

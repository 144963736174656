<template>
    <div class="article-box" v-loading="loading">
        <!-- 导航 -->
        <div class="page-nav clearfix">
            <ul>
                <li class = "nav-item">
                    会员管理&nbsp;&nbsp;/
                </li>
                <li>
                    <router-link class="nav-item" to="/institution/member/change">
                        &nbsp;&nbsp;个人会员管理&nbsp;&nbsp;/
                    </router-link>
                </li>
                <li class = "nav-item nav-item-last">
                    &nbsp;&nbsp;查看详情
                </li>
            </ul>
        </div>
        <!-- 标题 -->
        <div class="pag-title clearfix">
            <div class="page-title-name pull-left">基础资料</div>
        </div>
        <div style="padding:10px">
            <el-descriptions class="margin-top" title="变更单位代表信息" :column="3"  border>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">姓名</template>{{detailInfo.nickName || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">出生年月</template>{{detailInfo.birthday || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">性别</template>{{sexArr[detailInfo.sex ] || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">民族</template>{{detailInfo.national || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">政治面貌</template>{{detailInfo.politicalLandscape || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">学历</template>{{detailInfo.learningExperience || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">身份证号</template>{{detailInfo.idCard  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">手机</template>{{detailInfo.mobile  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">工作单位</template>{{detailInfo.unitName || '-' }}
                </el-descriptions-item>
                <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">国籍</template>{{ detailInfo.nationality || "-" }}
                </el-descriptions-item>
                <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">籍贯</template>{{ detailInfo.nativePlace || "-" }}
                </el-descriptions-item>
                <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">学位</template>{{ detailInfo.schoolRecord || "-" }}
                </el-descriptions-item>
                <el-descriptions-item label-class-name="my-label" content-class-name="my-content" :span="3">
                    <template slot="label">毕业院校</template>{{ detailInfo.graduateSchool || "-" }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">职务</template>{{detailInfo.position  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">技术职称</template>{{detailInfo.technicalTitles  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">专业专长</template>{{detailInfo.professional   || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">在职/离退休</template>{{detailInfo.workingState || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">Emai</template>{{detailInfo.email || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">邮政编码</template>{{detailInfo.zipCode || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :span="2">
                    <template slot="label">通讯地址</template>{{detailInfo.mailingAddress || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :span="2">
                    <template slot="label">是否院士</template>{{academicianArr[detailInfo.isAcademician]  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :span="2">
                    <template slot="label">活动参与分支机构</template>{{ detailInfo.partake.join(',')  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :span="3">
                    <template slot="label">关注分支机构</template>{{ detailInfo.follow.join(',')  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :span="3">
                    <template slot="label">照片</template>
                    <img :src="detailInfo.pictureFile" alt="" style="height: 100px;">
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :span="3">
                    <template slot="label">主要工作成果、业绩</template>{{detailInfo.workResult   || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :span="3">
                    <template slot="label">备注</template>{{detailInfo.fromRemarks  || '-'}}
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="margin-top" title="个人简历" :column="3"  border v-if="detailInfo.memberForResumes">
                <template  v-for="(item,index) in detailInfo.memberForResumes" >
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'time'+index">
                        <template slot="label">起止年月</template>{{item.time || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'unit'+index">
                        <template slot="label">单位</template>{{item.unit  || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'position'+index">
                        <template slot="label">职务</template>{{item.position  || '-'  }}
                    </el-descriptions-item>
                </template>
                <el-descriptions-item  label-class-name="no-my-label" content-class-name="my-content" v-if="!detailInfo.memberForResumes.length">
                    暂无数据
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="margin-top" title="论文" :column="4"  border v-if="detailInfo.memberForPapers">
                <template  v-for="(item,index) in detailInfo.memberForPapers">
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content"  :key="'title'+index">
                        <template slot="label">论文题目</template>{{item.title  || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content"  :key="'journalName'+index">
                        <template slot="label">期刊名称</template>{{item.journalName   || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content"  :key="'volumeIssue'+index">
                        <template slot="label">年卷期号</template>{{item.volumeIssue   || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content"  :key="'ranking'+index">
                        <template slot="label">本人排名</template>{{item.ranking   || '-'  }}
                    </el-descriptions-item>
                </template>
                <el-descriptions-item  label-class-name="no-my-label" content-class-name="my-content" v-if="!detailInfo.memberForPapers.length">
                    暂无数据
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="margin-top" title="著作" :column="4"  border v-if="detailInfo.memberForBooks ">
                <template  v-for="(item,index) in detailInfo.memberForBooks ">
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content"  :key="'bookName '+index">
                        <template slot="label">著作名称</template>{{item.bookName || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content"  :key="'publicationDate '+index">
                        <template slot="label">出版时间</template>{{item.publicationDate  || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content"  :key="'press '+index">
                        <template slot="label">出版社</template>{{item.press  || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content"  :key="'ranking2 '+index">
                        <template slot="label">本人排名</template>{{item.ranking || '-'  }}
                    </el-descriptions-item>
                </template>
                <el-descriptions-item  label-class-name="no-my-label" content-class-name="my-content" v-if="!detailInfo.memberForBooks.length">
                    暂无数据
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="margin-top" title="专利" :column="5"  border v-if="detailInfo.memberForAccredits">
                <template  v-for="(item,index) in detailInfo.memberForAccredits" >
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content"  :key="'category'+index">
                        <template slot="label">类别</template>{{item.category  || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'patentName'+index">
                        <template slot="label">专利名称</template>{{item.patentName  || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'patentNo'+index">
                        <template slot="label">专利号</template>{{item.patentNo  || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'accreditDate'+index">
                        <template slot="label">授奖时间</template>{{item.accreditDate  || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'ranking3'+index">
                        <template slot="label">本人排名</template>{{item.ranking || '-'  }}
                    </el-descriptions-item>
                </template>
                <el-descriptions-item  label-class-name="no-my-label" content-class-name="my-content" v-if="!detailInfo.memberForAccredits.length">
                    暂无数据
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="margin-top" title="主要奖励、荣誉" :column="4"  border  v-if="detailInfo.memberForRewards">
                <template  v-for="(item,index) in detailInfo.memberForRewards">
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content"  :key="'rewardName'+index" >
                        <template slot="label">奖励、荣誉名称</template>{{item.rewardName   || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content"  :key="'prizeUnit'+index">
                        <template slot="label">授奖单位</template>{{item.prizeUnit   || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content"  :key="'time2'+index">
                        <template slot="label">获奖时间</template>{{item.time   || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content"  :key="'ranking5'+index">
                        <template slot="label">本人排名</template>{{item.ranking || '-'  }}
                    </el-descriptions-item>
                </template>
                <el-descriptions-item  label-class-name="no-my-label" content-class-name="my-content" v-if="!detailInfo.memberForRewards.length">
                    暂无数据
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="margin-top" title="附件" :column="1"  border >
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">工作成果、业绩证明材料</template>
                    <div class="file-show-list">
                        <div v-for="(f) in detailInfo.resultProveInfos" :key="f.id" class="file-show-item">
                             <i class="el-icon-document" style="vertical-align: middle;margin-right: 10px;font-size: 18px;"></i>
                             <span class="li-text">{{ f.remark }}</span>
                             <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                        </div>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">论文证明材料</template>
                    <div class="file-show-list">
                        <div v-for="(f) in detailInfo.paperProveInfos" :key="f.id" class="file-show-item">
                             <i class="el-icon-document" style="vertical-align: middle;margin-right: 10px;font-size: 18px;"></i>
                             <span class="li-text">{{ f.remark }}</span>
                             <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                        </div>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">著作证明材料</template>
                    <div class="file-show-list">
                        <div v-for="(f) in detailInfo.bookProveInfos" :key="f.id" class="file-show-item">
                             <i class="el-icon-document" style="vertical-align: middle;margin-right: 10px;font-size: 18px;"></i>
                             <span class="li-text">{{ f.remark }}</span>
                             <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                        </div>
                    </div>
                    <!-- <div class="file-down-item" v-for="(f,i) in detailInfo.bookProveInfos" :key="'file'+i" @click="download(f.path, f.fileName)">{{f.fileName}}</div> -->
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">专利证明材料</template>
                    <div class="file-show-list">
                        <div v-for="(f) in detailInfo.patentProveInfos" :key="f.id" class="file-show-item">
                             <i class="el-icon-document" style="vertical-align: middle;margin-right: 10px;font-size: 18px;"></i>
                             <span class="li-text">{{ f.remark }}</span>
                             <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                        </div>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">荣誉、奖励证明材料</template>
                    <div class="file-show-list">
                        <div v-for="(f) in detailInfo.rewardProveInfos" :key="f.id" class="file-show-item">
                             <i class="el-icon-document" style="vertical-align: middle;margin-right: 10px;font-size: 18px;"></i>
                             <span class="li-text">{{ f.remark }}</span>
                             <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                        </div>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">签字及加盖公章后的pdf文件</template>
                    <div class="file-show-list">
                        <div v-for="(f) in detailInfo.pdfInfo" :key="f.id" class="file-show-item">
                             <i class="el-icon-document" style="vertical-align: middle;margin-right: 10px;font-size: 18px;"></i>
                             <span class="li-text">{{ f.remark }}</span>
                             <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                        </div>
                    </div>
                </el-descriptions-item>

                <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">身份证正面pdf文件</template>
              <div class="file-show-list">
                <div v-for="f in detailInfo.idCardFrs" :key="f.id" class="file-show-item">
                  <i class="el-icon-document" style="
                      vertical-align: middle;
                      margin-right: 10px;
                      font-size: 18px;
                    "></i>
                  <span class="li-text">{{ f.remark }}</span>
                  <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                </div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">身份证国徽面pdf文件</template>
              <div class="file-show-list">
                <div v-for="f in detailInfo.idCardBks" :key="f.id" class="file-show-item">
                  <i class="el-icon-document" style="
                      vertical-align: middle;
                      margin-right: 10px;
                      font-size: 18px;
                    "></i>
                  <span class="li-text">{{ f.remark }}</span>
                  <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                </div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" content-class-name="my-content">
              <template slot="label">职称证书pdf文件</template>
              <div class="file-show-list">
                <div v-for="f in detailInfo.degreeFiles" :key="f.id" class="file-show-item">
                  <i class="el-icon-document" style="
                      vertical-align: middle;
                      margin-right: 10px;
                      font-size: 18px;
                    "></i>
                  <span class="li-text">{{ f.remark }}</span>
                  <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                </div>
              </div>
            </el-descriptions-item>

            </el-descriptions>
        </div>
    </div>
</template>
<script>
import { InstitutionPersonalMemberDetail} from "@/http/api/InstitutionApi";
import { FileDownload } from '@/http/api/FileApi';
export default {
  components: { },
  data () {
    return {
        uid: '',
        loading: false,
        sexArr: ['', '男', '女'],
        academicianArr: ['', '中科院','工程院','其他国家','否'],
        detailInfo:{
            memberInstitutions: [], // 分支机构
            memberForResumes: [], // 简历
            memberForPapers: [], // 论文
            memberForBooks: [], // 著作
            memberForAccredits: [], // 专利
            memberForRewards: [],// 奖励荣誉集合 ,
            resultProveInfos: [], // 工作成果、业绩证明材料 附件
            paperProveInfos: [], // 论文 附件
            patentProveInfos: [], // 专利 附件
            bookProveInfos: [],// 著作 附件
            rewardProveInfos: [],// pdf 附件
            follow: [], //关注分支机构
            partake: [],//参与分支机构
            
        }
    }
  },
  created () {
    this.uid = this.$route.query.id
    this.getDetail()
  },
  methods: {
    getDetail () {
        InstitutionPersonalMemberDetail({id: this.uid}).then(resp => {
        if (resp.code == '000') {
            this.detailInfo = {...resp.result}
            this.detailInfo.pictureFile  = process.env.VUE_APP_IMG_URL + resp.result.pictureFile; // 照片
            this.detailInfo.memberInstitutions  = resp.result.memberInstitutions  || []; // 分支机构
            this.detailInfo.memberForResumes = resp.result.memberForResumes || []; // 简历
            this.detailInfo.memberForPapers = resp.result.memberForPapers || []; // 论文
            this.detailInfo.memberForBooks  = resp.result.memberForBooks  || []; // 著作
            this.detailInfo.memberForAccredits = resp.result.memberForAccredits || []; // 专利
            this.detailInfo.memberForRewards = resp.result.memberForRewards || []; // 奖励荣誉集合 ,
            this.detailInfo.resultProveInfos = resp.result.resultProveInfos || []; // 工作成果、业绩证明材料 附件
            this.detailInfo.paperProveInfos = resp.result.paperProveInfos || []; // 论文 附件
            this.detailInfo.patentProveInfos  = resp.result.patentProveInfos  || []; // 专利 附件
            this.detailInfo.bookProveInfos   = resp.result.bookProveInfos   || []; // 著作 附件
            this.detailInfo.rewardProveInfos   = resp.result.rewardProveInfos   || []; // 奖励 附件
            this.detailInfo.pdfInfo   = resp.result.pdfInfo   || []; // pdf 附件
            this.detailInfo.follow = resp.result.memberInstitutions.filter(f => {
                 if (f.type == 2) {
                    return f
                }
            }).map(s => {
                    return s.nickName
            })
            this.detailInfo.partake = resp.result.memberInstitutions.filter(f => {
                 if (f.type == 1) {
                    return f
                }
            }).map(s => {
                    return s.nickName
            })
            
        }
        })
    },
    download (path,name) {
        FileDownload(path, name)
    },
   
  }
}
</script>
<style lang="less" scoped>
/deep/.my-label {
    width: 150px;
}
/deep/.el-descriptions__header {
    margin-top: 20px;
}
/deep/.no-my-label {
    width: 0;
    padding: 0!important;
    border: none;
}

.file-show-list {
    box-sizing: border-box;
    width: 90%;
    padding: 10px;
    .file-show-item {
        width: 100%;
        padding: 5px 10px;
    }
    .file-show-item:hover{
        background-color: #f7f7f7;
    } 
    .downliad-file {
        font-size: 18px;
        vertical-align: middle;
    }
    .downliad-file:hover {
        color: #1E63B0;
    }
}


</style>

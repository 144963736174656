4<template>
  <div class="" style="min-height: 500px" v-loading="pageLoading">
    <Header :height="80"></Header>
    <div class="form-apply">
      <div class="title">中国交通运输协会个人会员入会申请表</div>
      <div v-if="state === 99 || formShow" class="">
        <el-form ref="form" :model="po" :rules="poRules" label-position="top">
          <!-- 1 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="姓名" :required="true">
                <el-input v-model="userInfo.nikeName" disabled placeholder="请输入姓名" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="出生年月" prop="birthday">
                <el-date-picker style="width: 100%" v-model="po.birthday" value-format="yyyy-MM-dd" type="date" placeholder="请选择出生年月" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="性别" prop="sex">
                <el-radio-group v-model="po.sex">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 2 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="民族" prop="national">
                <el-input v-model="po.national" placeholder="请输入民族"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="政治面貌" prop="politicalLandscape">
                <el-input v-model="po.politicalLandscape" placeholder="请输入政治面貌"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学历" prop="learningExperience">
                <el-input v-model="po.learningExperience" placeholder="请输入学历"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 3 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="身份证号" prop="idCard">
                <el-input v-model="po.idCard" placeholder="请输入身份证号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="手机" :required="true">
                <el-input v-model="userInfo.mobile" disabled placeholder="请输入手机"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="职务" :required="true">
                <el-input v-model="userInfo.position" disabled placeholder="请输入职务"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 4 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="国籍" prop="nationality">
                <el-input v-model="po.nationality" placeholder="请输入国籍"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="籍贯" prop="nativePlace">
                <el-input v-model="po.nativePlace" placeholder="请输入籍贯"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学位" prop="schoolRecord">
                <el-input v-model="po.schoolRecord" placeholder="请输入学位"></el-input>
              </el-form-item>
            </el-col>
            
          </el-row>
          <!-- 5 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="毕业院校" prop="graduateSchool">
                <el-input v-model="po.graduateSchool" placeholder="请输入毕业院校"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="工作单位" :required="true">
                <el-input v-model="userInfo.unitName" disabled placeholder="请输入工作单位"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 6 -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="一寸照片" prop="pictureFile">
                <UploadImage :config="uploadImageConfig" @UploadImageFun="UploadImageFun"></UploadImage>
                <span>请上传后缀未”jpeg,png,jpg“的图片</span>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 7 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="技术职称" prop="technicalTitles">
                <el-input v-model="po.technicalTitles" placeholder="请输入技术职称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="专业专长" prop="professional">
                <el-input v-model="po.professional" placeholder="请输入专业专长"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="在职/离退休" prop="workingState">
                <el-input v-model="po.workingState" placeholder="请输入在职/离退休"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 8 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="电子邮箱" prop="email">
                <el-input v-model="po.email" placeholder="请输入电子邮箱"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="通讯地址" prop="mailingAddress">
                <el-input v-model="po.mailingAddress" placeholder="请输入通讯地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="邮编" prop="zipCode">
                <el-input v-model="po.zipCode" placeholder="请输入邮编"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 9 -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="是否院士" prop="isAcademician">
                <el-radio-group v-model="po.isAcademician">
                  <el-radio :label="1">中科院</el-radio>
                  <el-radio :label="2">工程院</el-radio>
                  <el-radio :label="3">其他国家</el-radio>
                  <el-radio :label="4">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 表格 个人工作简历 -->
          <el-form-item label="个人工作简历" style="margin-bottom: 0;"></el-form-item>
          <div class="form-item-from-box">
            <el-table border :data="po.memberForResumes" highlight-current-row style="width: 100%">
              <el-table-column prop="time" label="起始年月" align="center" min-width="30%">
              </el-table-column>
              <el-table-column prop="unit" label="单位" align="center" min-width="40%">
              </el-table-column>
              <el-table-column prop="position" label="职务" align="center" min-width="15%">
              </el-table-column>
              <el-table-column prop="id" align="center" min-width="15%">
                <template slot-scope="scope">
                  <span class="text-btn" @click="resumeItemClick(1, scope.$index)"><i class="el-icon-edit"></i></span>
                  <span class="text-btn danger" @click="resumeItemClick(2, scope.$index)"><i
                      class="el-icon-delete"></i></span>
                </template>
              </el-table-column>
            </el-table>
            <el-button class="add-btn" type="primary" circle size="mini" icon="el-icon-plus" @click="resume.show = true"></el-button>
          </div>
          <el-form-item label="主要工作成果、业绩（下方附证明材料）" prop="workResult">
            <el-input style="width: 100%" type="textarea" :rows="3" v-model.trim="po.workResult"
              placeholder="请输入主要工作成果、业绩"></el-input>
          </el-form-item>
          <!-- 论文 -->
          <el-form-item label="发表论文、著作及专利授权情况（各填写主要的3个，下方附证明材料）" style="margin-bottom: 0;"></el-form-item>
          <div class="form-item-from-box">
            <el-table border :data="po.memberForPapers" highlight-current-row style="width: 100%">
              <el-table-column prop="title" label="论文题目" align="center" min-width="35%">
              </el-table-column>
              <el-table-column prop="journalName" label="期刊名称" align="center" min-width="23%">
              </el-table-column>
              <el-table-column prop="volumeIssue" label="年卷期号" align="center" min-width="15%">
              </el-table-column>
              <el-table-column prop="ranking" label="本人排名" align="center" min-width="12%">
              </el-table-column>
              <el-table-column prop="id" align="center" min-width="15%">
                <template slot-scope="scope">
                  <span class="text-btn" @click="work1ItemClick(1, scope.$index)"><i class="el-icon-edit"></i></span>
                  <span class="text-btn danger" @click="work1ItemClick(2, scope.$index)"><i
                      class="el-icon-delete"></i></span>
                </template>
              </el-table-column>
            </el-table>
            <el-button class="add-btn" type="primary" circle size="mini" icon="el-icon-plus" @click="work1DialogShow">
            </el-button>
          </div>
          <!-- 著作 -->
          <div class="form-item-from-box">
            <el-table border :data="po.memberForBooks" highlight-current-row style="width: 100%">
              <el-table-column prop="bookName" label="著作名称" align="center" min-width="35%">
              </el-table-column>
              <el-table-column prop="publicationDate" label="出版时间" align="center" min-width="20%">
              </el-table-column>
              <el-table-column prop="press" label="出版社" align="center" min-width="18%">
              </el-table-column>
              <el-table-column prop="ranking" label="本人排名" align="center" min-width="12%">
              </el-table-column>
              <el-table-column prop="id" align="center" min-width="15%">
                <template slot-scope="scope">
                  <span class="text-btn" @click="work2ItemClick(1, scope.$index)"><i class="el-icon-edit"></i></span>
                  <span class="text-btn danger" @click="work2ItemClick(2, scope.$index)"><i
                      class="el-icon-delete"></i></span>
                </template>
              </el-table-column>
            </el-table>
            <el-button class="add-btn" type="primary" circle size="mini" icon="el-icon-plus" @click="work2DialogShow">
            </el-button>
          </div>
          <!-- 专利授权 -->
          <div class="form-item-from-box">
            <el-table border :data="po.memberForAccredits" highlight-current-row style="width: 100%">
              <el-table-column prop="category" label="类别(发明,实用新型,外观设计)" align="center" min-width="30%">
              </el-table-column>
              <el-table-column prop="patentName" label="专利名称" align="center" min-width="20%">
              </el-table-column>
              <el-table-column prop="patentNo" label="专利号" align="center" min-width="23%">
              </el-table-column>
              <el-table-column prop="accreditDate" label="授权时间" align="center" min-width="18%">
              </el-table-column>
              <el-table-column prop="ranking" label="本人排名" align="center" min-width="12%">
              </el-table-column>
              <el-table-column prop="id" align="center" min-width="15%">
                <template slot-scope="scope">
                  <span class="text-btn" @click="work3ItemClick(1, scope.$index)"><i class="el-icon-edit"></i></span>
                  <span class="text-btn danger" @click="work3ItemClick(2, scope.$index)"><i
                      class="el-icon-delete"></i></span>
                </template>
              </el-table-column>
            </el-table>
            <el-button class="add-btn" type="primary" circle size="mini" icon="el-icon-plus" @click="work3DialogShow">
            </el-button>
          </div>
          <!-- 奖励、荣誉 -->
          <div class="form-item-from-box">
            <el-table border :data="po.memberForRewards" highlight-current-row style="width: 100%">
              <el-table-column prop="rewardName" label="奖励名称" align="center" min-width="30%">
              </el-table-column>
              <el-table-column prop="time" label="获奖时间" align="center" min-width="20%">
              </el-table-column>
              <el-table-column prop="prizeUnit" label="授奖单位" align="center" min-width="23%">
              </el-table-column>
              <el-table-column prop="ranking" label="本人排名" align="center" min-width="12%">
              </el-table-column>
              <el-table-column prop="id" align="center" min-width="15%">
                <template slot-scope="scope">
                  <span class="text-btn" @click="work4ItemClick(1, scope.$index)"><i class="el-icon-edit"></i></span>
                  <span class="text-btn danger" @click="work4ItemClick(2, scope.$index)"><i
                      class="el-icon-delete"></i></span>
                </template>
              </el-table-column>
            </el-table>
            <el-button class="add-btn" type="primary" circle size="mini" icon="el-icon-plus" @click="work4DialogShow">
            </el-button>
          </div>
          <!-- 分支机构 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="活动参加分支机构" prop="instId">
                <el-select v-model="po.instId" clearable placeholder="请选择活动参加分支机构" @change="instIdChange" style="width: 100%;">
                  <el-option v-for="(item, index) of instList" :key="index" :label="item.nickName" :value="item.institutionsId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item v-show="po.instId" label-width="150px" label="关注其他分支机构" prop="instArr">
                <el-select v-model="po.instArr" multiple placeholder="请选择关注其他分支机构" style="width: 100%;">
                  <el-option v-for="(item, index) of instList1" :key="index" :label="item.nickName" :value="item.institutionsId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 备注 -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="备注" >
                <el-input v-model="po.fromRemarks" type="textarea" placeholder="请输入备注">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item >
            <div style="font-weight: 700;">
              请填写完成后
              <span class="text-btn" @click="download('addForm1')">点击此处</span>
              下载pdf文件后签字并加盖公章后在下方上传
            </div>
          </el-form-item>
          <!-- 上传文件 -->
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="resultProveFiles" label="请上传工作成果、业绩证明材料（限3个PDF文件）">
                <el-checkbox-group v-model="po.resultProveFiles" v-show="false"></el-checkbox-group>
                <UploadFile :config="uploadFileConfig1" @UploadFileFun="UploadFileFun1" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <div></div>
              <el-form-item label="请上传论文证明材料（限3个PDF文件）" prop="paperProveFiles">
                <UploadFile :config="uploadFileConfig2" @UploadFileFun="UploadFileFun2" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="bookProveFiles" label="请上传著作证明材料（限3个PDF文件）">
                <UploadFile :config="uploadFileConfig3" @UploadFileFun="UploadFileFun3" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="patentProveFiles" label="请上传专利证明材料（限3个PDF文件）">
                <UploadFile :config="uploadFileConfig4" @UploadFileFun="UploadFileFun4" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="rewardProveFiles" label="请上传荣誉、奖励证明材料（限3个PDF文件）">
                <UploadFile :config="uploadFileConfig5" @UploadFileFun="UploadFileFun5" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="pdfFile" label="请上传签字及加盖公章后的pdf文件">
                <el-checkbox-group v-model="po.pdfFile" v-show="false"></el-checkbox-group>
                <UploadFile :config="uploadFileConfig6" @UploadFileFun="UploadFileFun6" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="idCardFr" label="请上传身份证正面pdf文件">
                <el-checkbox-group v-model="po.idCardFr" v-show="false"></el-checkbox-group>
                <UploadFile :config="uploadFileConfig7" @UploadFileFun="UploadFileFun7" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="idCardBk" label="请上传身份证国徽面pdf文件">
                <el-checkbox-group v-model="po.idCardBk" v-show="false"></el-checkbox-group>
                <UploadFile :config="uploadFileConfig8" @UploadFileFun="UploadFileFun8" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="degreeFile" label="请上传职称证书pdf文件">
                <el-checkbox-group v-model="po.degreeFile" v-show="false"></el-checkbox-group>
                <UploadFile :config="uploadFileConfig9" @UploadFileFun="UploadFileFun9" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label-width="0" style="text-align: center; margin-top: 50px">
            <el-button type="primary" :loading="submitLoading" @click="submit(1)">保 存</el-button>
            <el-button type="primary" :loading="submitLoading" @click="submit(2)">提 交</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-else class="form-box">
        <div v-if="state !== -1">
          <div class="state-title">
            <i v-if="state === 1" class="el-icon-pie-chart"></i>
            <span v-if="state === 1">您已提交入会申请，请等待审核</span>
            <span v-if="state === 2">您的入会申请已经通过</span>
            <span v-if="state === 3">管理员拒绝了您的入会申请，原因：{{ remarks }}</span>
            <span v-if="state === 4">您的入会申请被管理员退回，退回原因：{{ remarks }}</span>
            <span v-if="state === 5">您的入会申请修改已经再次提交，请等待审核</span>

            <div style="margin: 40px 0;">
              <el-button v-if="state === 4" type="primary" @click="again(1)">返回修改</el-button>
              <el-button v-if="state === 3" type="primary" @click="again(2)">重新填写</el-button>
            </div>
          </div>
          <div class="record-box">
            <div style="margin-bottom: 30px;">申请记录</div>
            <div class="record-list">
              <el-table border :data="recordList" highlight-current-row header-row-class-name="table-header-class"
                :header-cell-style="{ 'background-color': '#F7F8FA' }" style="width: 100%; min-height: 200px">
                <el-table-column prop="nickName" label="申请人" min-width="30%">
                </el-table-column>
                <el-table-column prop="createDate" label="申请时间" align="center" min-width="40%">
                </el-table-column>
                <el-table-column prop="state " label="状态" align="center" min-width="30%">
                  <template slot-scope="scope">
                    <span v-if="scope.row.state === 1">待确认</span>
                    <span v-if="scope.row.state === 2">已同意</span>
                    <span v-if="scope.row.state === 3">已拒绝</span>
                    <span v-if="scope.row.state === 4">退回修改</span>
                    <span v-if="scope.row.state === 5">再次确认</span>
                    <el-tooltip v-if="
                      (scope.row.state === 3 || scope.row.state === 4) &&
                      scope.row.remarks
                    " :content="scope.row.remarks" placement="top">
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加或修改工作简历 -->
    <el-dialog title="工作简历" :visible="resume.show" width="600px" :close-on-click-modal="false" :show-close="true"
      @close="resumeClear">
      <el-form ref="resumeForm" :model="resume.formData" class="demo-form-inline" label-width="100px"
        :rules="resume.rules">
        <el-form-item label="起止年月:" prop="time">
          <el-input v-model="resume.formData.time" placeholder="请输入起止年月"></el-input>
        </el-form-item>
        <el-form-item label="单位:" prop="unit">
          <el-input v-model="resume.formData.unit" placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item label="职务:" prop="position">
          <el-input v-model="resume.formData.position" placeholder="请输入职务"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain @click="resumeClear">取消</el-button>
        <el-button type="primary" size="medium" @click.native.prevent="resumeSubmit()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加或修改论文 -->
    <el-dialog title="论文" :visible="work1.show" width="600px" :close-on-click-modal="false" :show-close="true"
      @close="work1Clear">
      <el-form ref="work1Form" :model="work1.formData" class="demo-form-inline" label-width="100px"
        :rules="work1.rules">
        <el-form-item label="论文题目:" prop="title">
          <el-input v-model="work1.formData.title" placeholder="请输入论文题目"></el-input>
        </el-form-item>
        <el-form-item label="期刊名称:" prop="journalName">
          <el-input v-model="work1.formData.journalName" placeholder="请输入期刊名称"></el-input>
        </el-form-item>
        <el-form-item label="年卷期号:" prop="volumeIssue">
          <el-input v-model="work1.formData.volumeIssue" placeholder="请输入年卷期号"></el-input>
        </el-form-item>
        <el-form-item label="本人排名:" prop="ranking">
          <el-input v-model="work1.formData.ranking" placeholder="请输入本人排名"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain @click="work1Clear">取消</el-button>
        <el-button type="primary" size="medium" @click.native.prevent="work1Submit()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加或修改著作 -->
    <el-dialog title="著作" :visible="work2.show" width="600px" :close-on-click-modal="false" :show-close="true"
      @close="work2Clear">
      <el-form ref="work2Form" :model="work2.formData" class="demo-form-inline" label-width="100px"
        :rules="work2.rules">
        <el-form-item label="著作名称:" prop="bookName">
          <el-input v-model="work2.formData.bookName" placeholder="请输入著作名称"></el-input>
        </el-form-item>
        <el-form-item label="出版时间:" prop="publicationDate">
          <el-input v-model="work2.formData.publicationDate" placeholder="请输入出版时间"></el-input>
        </el-form-item>
        <el-form-item label="出版社:" prop="press">
          <el-input v-model="work2.formData.press" placeholder="请输入出版社"></el-input>
        </el-form-item>
        <el-form-item label="本人排名:" prop="ranking">
          <el-input v-model="work2.formData.ranking" placeholder="请输入本人排名"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain @click="work2Clear">取消</el-button>
        <el-button type="primary" size="medium" @click.native.prevent="work2Submit()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加或修改专利授权 -->
    <el-dialog title="专利授权" :visible="work3.show" width="600px" :close-on-click-modal="false" :show-close="true"
      @close="work3Clear">
      <el-form ref="work3Form" :model="work3.formData" class="demo-form-inline" label-width="100px"
        :rules="work3.rules">
        <el-form-item label="专利类别:" prop="category">
          <el-input v-model="work3.formData.category" placeholder="请输入专利类别"></el-input>
        </el-form-item>
        <el-form-item label="专利名称:" prop="patentName">
          <el-input v-model="work3.formData.patentName" placeholder="请输入专利名称"></el-input>
        </el-form-item>
        <el-form-item label="专利号:" prop="patentNo">
          <el-input v-model="work3.formData.patentNo" placeholder="请输入专利号"></el-input>
        </el-form-item>
        <el-form-item label="授权时间:" prop="accreditDate">
          <el-input v-model="work3.formData.accreditDate" placeholder="请输入授权时间"></el-input>
        </el-form-item>
        <el-form-item label="本人排名:" prop="ranking">
          <el-input v-model="work3.formData.ranking" placeholder="请输入本人排名"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain @click="work3Clear">取消</el-button>
        <el-button type="primary" size="medium" @click.native.prevent="work3Submit()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加或修改奖励、荣誉 -->
    <el-dialog title="奖励、荣誉" :visible="work4.show" width="600px" :close-on-click-modal="false" :show-close="true"
      @close="work4Clear">
      <el-form ref="work4Form" :model="work4.formData" class="demo-form-inline" label-width="100px"
        :rules="work4.rules">
        <el-form-item label="奖励名称:" prop="rewardName">
          <el-input v-model="work4.formData.rewardName" placeholder="请输入奖励名称"></el-input>
        </el-form-item>
        <el-form-item label="获奖时间:" prop="time">
          <el-input v-model="work4.formData.time" placeholder="请输入获奖时间"></el-input>
        </el-form-item>
        <el-form-item label="授奖单位:" prop="prizeUnit">
          <el-input v-model="work4.formData.prizeUnit" placeholder="请输入授奖单位"></el-input>
        </el-form-item>
        <el-form-item label="本人排名:" prop="ranking">
          <el-input v-model="work4.formData.ranking" placeholder="请输入本人排名"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain @click="work4Clear">取消</el-button>
        <el-button type="primary" size="medium" @click.native.prevent="work4Submit()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script >
import { Component, Vue, Ref } from "vue-property-decorator";
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
import UploadFile from "@c/upload/UploadFile.vue";
import UploadImage from "@c/upload/UploadImage.vue";
import { IndexStore } from "@/store/module/index";
import FileEcho from '@c/upload/FileEcho.vue';
import {
  GetInstitutionAllList,
  GetApplyState,
  GetApplyDetail,
  PersonageApplyAdd,
  GetApplyRecordList,
} from "@/http/api/InitiationApi";
import { FileDownload2 } from "@/http/api/FileApi"

export default {
  components: {
    Header,
    UploadFile,
    UploadImage,
    Footer,
    FileEcho
  },
  data() {
    return {
      pageLoading: false,
      submitLoading: false,
      id: "", // 申请ID 退回修改时使用
      remarks: "", // 退回原因
      formShow: false, // 表单是否显示
      state: -1, // 状态 1.待确认 2.已同意 3.已拒绝 4.退回修改 5.再次确认 6.草稿 99.没有状态第一次进来
      recordList: [], // 申请记录列表
      userInfo: IndexStore.userInfo,
      po: {
        nationality: '', // 国籍
        nativePlace: '', // 籍贯
        schoolRecord: '', // 学历证书
        graduateSchool: '', // 毕业院校
        // ＋
        idCard: '',
        fromRemarks: '',
        sex: '', // 性别
        pictureFile: "", // 一寸照片路径
        instId: "", // 活动参加分支机构ID
        instArr: [], // 关注其他分支机构集合
        birthday: "", // 出生日期
        national: "", // 民族
        politicalLandscape: "", // 政治面貌
        learningExperience: "", // 学历
        technicalTitles: "", // 技术职称
        professional: "", // 专业专长
        workingState: "", // 在职/离退休
        email: "", // 邮箱
        mailingAddress: "", // 通讯地址
        zipCode: "", // 邮编
        isAcademician: '', // 是否院士 1.中科院 2.工程院 3.其他国家 4.否
        workResult: "", // 主要工作成果、业绩
        bookProveFiles: "", // 著作证明附件集合逗号分隔
        patentProveFiles: "", // 专利证明附件集合逗号分隔
        paperProveFiles: "", // 论文证明附件集合逗号分隔
        resultProveFiles: "", // 业绩证明附件集合逗号分隔
        rewardProveFiles: "", // 奖励证明附件集合逗号分隔
        pdfFile: "", // pdf单文件,单文件

        // 身份证文件
        idCardFr: '',
        idCardBk: '',
        degreeFile: '',
        memberForResumes: [], // 个人工作简历集合
        memberForPapers: [], // 发表论文集合
        memberForBooks: [], // 著作集合
        memberForAccredits: [], // 专利授权集合
        memberForRewards: [], // 奖励荣誉集合
        bookProveInfos: [
          {
            fileName: "", //string, optional): 文件名 ,
            id: "", //string, optional): 文件id ,
            path: "", //string, optional): 路径 ,
            remark: "", //string, optional): 文件真实名
          },
        ], //: 著作证明附件集合 ,
        paperProveInfos: [
          {
            fileName: "", //string, optional): 文件名 ,
            id: "", //string, optional): 文件id ,
            path: "", //string, optional): 路径 ,
            remark: "", //string, optional): 文件真实名
          },
        ], //: 论文证明附件集合 ,
        patentProveInfos: [
          {
            fileName: "", //string, optional): 文件名 ,
            id: "", //string, optional): 文件id ,
            path: "", //string, optional): 路径 ,
            remark: "", //string, optional): 文件真实名
          },
        ], //: 专利证明附件集合 ,
        pdfInfo: [
          {
            fileName: "", //string, optional): 文件名 ,
            id: "", //string, optional): 文件id ,
            path: "", //string, optional): 路径 ,
            remark: "", //string, optional): 文件真实名
          },
        ], //: pdf ,
        rewardProveInfos: [
          {
            fileName: "", //string, optional): 文件名 ,
            id: "", //string, optional): 文件id ,
            path: "", //string, optional): 路径 ,
            remark: "", //string, optional): 文件真实名
          },
        ], //: 奖励证明附件集合 ,
        pictureInfo: [
          {
            fileName: "", //string, optional): 文件名 ,
            id: "", //string, optional): 文件id ,
            path: "", //string, optional): 路径 ,
            remark: "", //string, optional): 文件真实名
          },
        ], //: 用户照片 ,
        resultProveInfos: [
          {
            fileName: "", //string, optional): 文件名 ,
            id: "", //string, optional): 文件id ,
            path: "", //string, optional): 路径 ,
            remark: "", //string, optional): 文件真实名
          },
        ], //: 业绩证明附件集合 ,
        // 身份证文件+
        // idCardFr: [
        //   {
        //     fileName: "", //string, optional): 文件名 ,
        //     id: "", //string, optional): 文件id ,
        //     path: "", //string, optional): 路径 ,
        //     remark: "", //string, optional): 文件真实名
        //   },
        // ], //
        // idCardBk: [
        //   {
        //     fileName: "", //string, optional): 文件名 ,
        //     id: "", //string, optional): 文件id ,
        //     path: "", //string, optional): 路径 ,
        //     remark: "", //string, optional): 文件真实名
        //   },
        // ], // 身份证文件
        // degreeFile: [
        //   {
        //     fileName: "", //string, optional): 文件名 ,
        //     id: "", //string, optional): 文件id ,
        //     path: "", //string, optional): 路径 ,
        //     remark: "", //string, optional): 文件真实名
        //   },
        // ], // 学历证书文件

      },
      poRules: {
        idCardFr: [{ required: true, message: "请上传pdf文件", trigger: ["blur", "change"] }],
        idCardBk: [{ required: true, message: "请上传pdf文件", trigger: ["blur", "change"] }],
        degreeFile: [{ required: true, message: "请上传pdf文件", trigger: ["blur", "change"] }],
        nationality: [{ required: true, message: "请输入国籍", trigger: "blur" }], // 国籍
        nativePlace: [{ required: true, message: "请输入籍贯", trigger: "blur" }], // 籍贯
        schoolRecord: [{ required: true, message: "请输入学位", trigger: "blur" }], // 学历证书
        graduateSchool: [{ required: true, message: "请输入毕业院校", trigger: "blur" }], // 毕业院校
        idCard: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
        ],
        pictureFile: [
          { required: true, message: "请上传一寸照片", trigger: ["blur", "change"] },
        ],
        instId: [
          { required: true, message: "请选择活动参加分支机构", trigger: ["blur", "change"] },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: ["blur", "change"] }],
        birthday: [{ required: true, message: "请输入出生年月", trigger: "blur" }],
        national: [{ required: true, message: "请输入民族", trigger: "blur" }],
        politicalLandscape: [
          { required: true, message: "请输入政治面貌", trigger: "blur" },
        ],
        learningExperience: [
          { required: true, message: "请输入学历", trigger: "blur" },
        ],
        technicalTitles: [
          { required: true, message: "请输入技术职称", trigger: "blur" },
        ],
        professional: [
          { required: true, message: "请输入专业专长", trigger: "blur" },
        ],
        workingState: [
          { required: true, message: "请输入在职/离退休", trigger: "blur" },
        ],
        email: [{ required: true, message: "请输入电子邮箱", trigger: "blur" }],
        mailingAddress: [
          { required: true, message: "请输入通讯地址", trigger: "blur" },
        ],
        zipCode: [{ required: true, message: "请输入邮编", trigger: "blur" }],
        isAcademician: [
          { required: true, message: "请选择是否院士", trigger: ["blur", "change"] },
        ],
        workResult: [
          { required: true, message: "请输入主要工作成果、业绩", trigger: "blur" },
        ],
        resultProveFiles: [
          {
            required: true,
            message: "请上传pdf文件",
            trigger: ["blur", "change"]
          },
        ],
        pdfFile: [
          {
            required: true,
            message: "请上传pdf文件",
            trigger: ["blur", "change"]
          },
        ],
      },
      instList: [],
      instList1: [],
      // 工作简历
      resume: {
        show: false,
        setIndex: -1,
        formData: {
          time: "",
          unit: "",
          position: "",
        },
        rules: {
          time: [{ required: true, message: "请输入起始年月", trigger: "blur" }],
          unit: [{ required: true, message: "请输入单位", trigger: "blur" }],
          position: [{ required: true, message: "请输入职务", trigger: "blur" }],
        },
      },
      // 论文
      work1: {
        show: false,
        setIndex: -1,
        formData: {
          title: "",
          journalName: "",
          volumeIssue: "",
          ranking: "",
        },
        rules: {
          title: [{ required: true, message: "请输入论文题目", trigger: "blur" }],
          journalName: [
            { required: true, message: "请输入期刊名称", trigger: "blur" },
          ],
          volumeIssue: [
            { required: true, message: "请输入年卷期号", trigger: "blur" },
          ],
          ranking: [{ required: true, message: "请输入本人排名", trigger: "blur" }],
        },
      },
      // 著作
      work2: {
        show: false,
        list: [],
        setIndex: -1,
        formData: {
          bookName: "",
          publicationDate: "",
          press: "",
          ranking: "",
        },
        rules: {
          bookName: [
            { required: true, message: "请输入著作名称", trigger: "blur" },
          ],
          publicationDate: [
            { required: true, message: "请输入出版时间", trigger: "blur" },
          ],
          press: [{ required: true, message: "请输入出版社", trigger: "blur" }],
          ranking: [{ required: true, message: "请输入本人排名", trigger: "blur" }],
        },
      },
      // 专利授权
      work3: {
        show: false,
        list: [],
        setIndex: -1,
        formData: {
          category: "",
          patentName: "",
          patentNo: "",
          accreditDate: "",
          ranking: "",
        },
        rules: {
          category: [
            { required: true, message: "请输入专利类别", trigger: "blur" },
          ],
          patentName: [
            { required: true, message: "请输入专利名称", trigger: "blur" },
          ],
          patentNo: [{ required: true, message: "请输入专利号", trigger: "blur" }],
          accreditDate: [
            { required: true, message: "请输入授权时间", trigger: "blur" },
          ],
          ranking: [{ required: true, message: "请输入本人排名", trigger: "blur" }],
        },
      },
      // 奖励、荣誉
      work4: {
        show: false,
        list: [],
        setIndex: -1,
        formData: {
          rewardName: "",
          time: "",
          prizeUnit: "",
          ranking: "",
        },
        rules: {
          rewardName: [
            { required: true, message: "请输入奖励名称", trigger: "blur" },
          ],
          time: [{ required: true, message: "请输入获奖时间", trigger: "blur" }],
          prizeUnit: [
            { required: true, message: "请输入授奖单位", trigger: "blur" },
          ],
          ranking: [{ required: true, message: "请输入本人排名", trigger: "blur" }],
        },
      },
      uploadImageConfig: {
        limit: 1,
        callback: "",
      },
      uploadFileConfig1: {
        limit: 3,
        callback: [],
      },
      uploadFileConfig2: {
        limit: 3,
        callback: [],
      },
      uploadFileConfig3: {
        limit: 3,
        callback: [],
      },
      uploadFileConfig4: {
        limit: 3,
        callback: [],
      },
      uploadFileConfig5: {
        limit: 3,
        callback: [],
      },
      uploadFileConfig6: {
        limit: 1,
        callback: [],
      },
      uploadFileConfig7: {
        limit: 1,
        callback: [],
      },
      uploadFileConfig8: {
        limit: 1,
        callback: [],
      },
      uploadFileConfig9: {
        limit: 1,
        callback: [],
      },
    }
  },
  created() {
    this.getUserInfoFun();
    this.getInstitutionList();
    this.getState();
  },
  methods: {
    download() {
      const parmas = JSON.parse(JSON.stringify(this.po));
      parmas.nikeName = this.userInfo.nikeName
      parmas.mobile = this.userInfo.mobile
      parmas.idCard = this.po.idCard
      parmas.position = this.userInfo.position
      parmas.unitName = this.userInfo.unitName
      parmas.memberInstitutions = [];
      parmas.focusOrgNames = ''
      parmas.joinOrgName = ''
      if (parmas.isAcademician == 1) {
        parmas.isAcademician = '中科院 '
      } else if (parmas.isAcademician == 2) {
        parmas.isAcademician = '工程院 '
      } else if (parmas.isAcademician == 3) {
        parmas.isAcademician = '其他国家 '
      } else if (parmas.isAcademician == 4) {
        parmas.isAcademician = '否'
      }
      if (parmas.sex == 2) {
        parmas.sex = '女'
      } else if (parmas.sex == 1) {
        parmas.sex = '男'
      }
      if (this.po.instId) {
        this.instList.forEach(item => {
          if (item.institutionsId == this.po.instId) {
            parmas.joinOrgName = item.nickName
          }
        })
      }
      if (this.po.instArr.length > 0) {
        const arr = []
        this.po.instArr.forEach(ele => {
          this.instList1.forEach(eles => {
            if (eles.institutionsId == ele) {
              arr.push(eles.nickName)
            }
          })
        });
        parmas.focusOrgNames = arr.join()
      }
      // parmas.birthday = new Date(parmas.birthday);
      FileDownload2("/member/web/pdf/member/person", parmas)
    },
    // 提交+保存
    submit(flag) {
      this.$confirm(`确定要${flag == 1 ? "保存" : "提交"}申请表吗？`, "确认提交",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        const params = this.po;
        params.memberInstitutions = [];
        if (this.po.instId) {
          params.memberInstitutions.push({
            type: 1,
            institutionsId: this.po.instId,
          });
        }
        this.po.instArr.forEach((ele) => {
          params.memberInstitutions.push({
            type: 2,
            institutionsId: ele,
          });
        });
        // params.birthday = new Date(params.birthday);
        params.isDraft = flag;
        if (this.id) {
          params.id = this.id;
        }
        if (flag !== 2) {
          this.submitLoading = true;
          PersonageApplyAdd(params).then((res) => {
            this.submitLoading = false;
            if (res.code === "000") {
              this.getState();
              this.$message.success("保存成功");
            }
          });
          console.log(this.po.pictureFile);

        } else {
          this.$refs.form.validate((bool) => {
            if (bool) {
              this.submitLoading = true;
              PersonageApplyAdd(params).then((res) => {
                this.submitLoading = false;
                if (res.code === "000") {
                  this.$message.success("成功");
                  this.getState();
                }
              });
            } else {
              this.$message.warning('必填项不能为空')
            }
          });
        }
      });
    },
    // 文件
    UploadFileFun1(val) {
      this.po.resultProveFiles = val;
    },
    UploadFileFun2(val) {
      this.po.paperProveFiles = val;
    },
    UploadFileFun3(val) {
      this.po.bookProveFiles = val;
    },
    UploadFileFun4(val) {
      this.po.patentProveFiles = val;
    },
    UploadFileFun5(val) {
      this.po.rewardProveFiles = val;
    },
    UploadFileFun6(val) {
      this.po.pdfFile = val;
    },
    UploadFileFun7(val) {
      this.po.idCardFr = val;
    },
    UploadFileFun8(val) {
      this.po.idCardBk = val;
    },
    UploadFileFun9(val) {
      this.po.degreeFile = val;
    },
    UploadImageFun(val) {
      console.log(val, 'images')
      this.po.pictureFile = val;
    },
    // 监听活动参加分支机构:
    instIdChange(e) {
      const arr = [];
      this.instList.forEach((element) => {
        if (element.institutionsId !== e) {
          arr.push({
            institutionsId: element.institutionsId,
            nickName: element.nickName,
          });
        }
      });
      this.instList1 = arr;
      this.po.instArr = [];
    },
    // 点击工作简历item
    resumeItemClick(flag, index) {
      if (flag == 1) {
        // 编辑
        const obj = this.po.memberForResumes[index];
        this.resume.formData.time = obj.time;
        this.resume.formData.unit = obj.unit;
        this.resume.formData.position = obj.position;
        this.resume.setIndex = index;
        this.resume.show = true;
      } else {
        // 删除
        this.$confirm(`确定要删除该条工作简历吗？`, "确认删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.po.memberForResumes.splice(index, 1);
          this.$message.success("删除成功");
        });
      }
    },
     // 提交工作经历
    resumeSubmit() {
      this.$refs.resumeForm.validate((bool) => {
        if (bool) {
          if (this.resume.setIndex !== -1) {
            this.po.memberForResumes[this.resume.setIndex].time =
              this.resume.formData.time;
            this.po.memberForResumes[this.resume.setIndex].unit =
              this.resume.formData.unit;
            this.po.memberForResumes[this.resume.setIndex].position =
              this.resume.formData.position;
          } else {
            this.po.memberForResumes.push({
              time: this.resume.formData.time,
              unit: this.resume.formData.unit,
              position: this.resume.formData.position,
            });
          }
          this.resumeClear();
        }
      });
    },
    // 关闭工作简历弹窗
    resumeClear() {
      this.resume.formData = {
        time: "",
        unit: "",
        position: "",
      };
      this.resume.setIndex = -1;
      this.resume.show = false;
    },
    // 打开论文弹窗
    work1DialogShow() {
      if (this.po.memberForPapers.length >= 3) {
        this.$message.warning("最多填写三条数据");
      } else {
        this.work1.show = true;
      }
    },
    // 点击论文item
    work1ItemClick(flag, index) {
      if (flag == 1) {
        // 编辑
        const obj = this.po.memberForPapers[index];
        this.work1.formData.title = obj.title;
        this.work1.formData.journalName = obj.journalName;
        this.work1.formData.volumeIssue = obj.volumeIssue;
        this.work1.formData.ranking = obj.ranking;
        this.work1.setIndex = index;
        this.work1.show = true;
      } else {
        // 删除
        this.$confirm(`确定要删除该条论文吗？`, "确认删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.po.memberForPapers.splice(index, 1);
          this.$message.success("删除成功");
        });
      }
    },
    // 提交论文
    work1Submit() {
      this.$refs.work1Form.validate((bool) => {
        if (bool) {
          if (this.work1.setIndex !== -1) {
            this.po.memberForPapers[this.work1.setIndex].title =
              this.work1.formData.title;
            this.po.memberForPapers[this.work1.setIndex].journalName =
              this.work1.formData.journalName;
            this.po.memberForPapers[this.work1.setIndex].volumeIssue =
              this.work1.formData.volumeIssue;
            this.po.memberForPapers[this.work1.setIndex].ranking =
              this.work1.formData.ranking;
          } else {
            this.po.memberForPapers.push({
              title: this.work1.formData.title,
              journalName: this.work1.formData.journalName,
              volumeIssue: this.work1.formData.volumeIssue,
              ranking: this.work1.formData.ranking,
            });
          }
          this.work1Clear();
        }
      });
    },
    // 关闭论文弹窗
    work1Clear() {
      this.work1.formData = {
        title: "",
        journalName: "",
        volumeIssue: "",
        ranking: "",
      };
      this.work1.setIndex = -1;
      this.work1.show = false;
    },
    // 打开著作弹窗
    work2DialogShow() {
      if (this.po.memberForBooks.length >= 3) {
        this.$message.warning("最多填写三条数据");
      } else {
        this.work2.show = true;
      }
    },
    // 点击著作item
    work2ItemClick(flag, index) {
      if (flag == 1) {
        // 编辑
        const obj = this.po.memberForBooks[index];
        this.work2.formData.bookName = obj.bookName;
        this.work2.formData.publicationDate = obj.publicationDate;
        this.work2.formData.press = obj.press;
        this.work2.formData.ranking = obj.ranking;
        this.work2.setIndex = index;
        this.work2.show = true;
      } else {
        // 删除
        this.$confirm(`确定要删除该条著作吗？`, "确认删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.po.memberForBooks.splice(index, 1);
          this.$message.success("删除成功");
        });
      }
    },
    // 提交著作
    work2Submit() {
      this.$refs.work2Form.validate((bool) => {
        if (bool) {
          if (this.work2.setIndex !== -1) {
            this.po.memberForBooks[this.work2.setIndex].bookName =
              this.work2.formData.bookName;
            this.po.memberForBooks[this.work2.setIndex].publicationDate =
              this.work2.formData.publicationDate;
            this.po.memberForBooks[this.work2.setIndex].press =
              this.work2.formData.press;
            this.po.memberForBooks[this.work2.setIndex].ranking =
              this.work2.formData.ranking;
          } else {
            this.po.memberForBooks.push({
              bookName: this.work2.formData.bookName,
              publicationDate: this.work2.formData.publicationDate,
              press: this.work2.formData.press,
              ranking: this.work2.formData.ranking,
            });
          }
          this.work2Clear();
        }
      });
    },
    // 关闭著作弹窗
    work2Clear() {
      this.work2.formData = {
        bookName: "",
        publicationDate: "",
        press: "",
        ranking: "",
      };
      this.work2.setIndex = -1;
      this.work2.show = false;
    },
    // 打开专利授权弹窗
    work3DialogShow() {
      if (this.po.memberForAccredits.length >= 3) {
        this.$message.warning("最多填写三条数据");
      } else {
        this.work3.show = true;
      }
    },
    // 点击专利授权item
    work3ItemClick(flag, index) {
      if (flag == 1) {
        // 编辑
        const obj = this.po.memberForAccredits[index];
        this.work3.formData.category = obj.category;
        this.work3.formData.patentName = obj.patentName;
        this.work3.formData.patentNo = obj.patentNo;
        this.work3.formData.accreditDate = obj.accreditDate;
        this.work3.formData.ranking = obj.ranking;
        this.work3.setIndex = index;
        this.work3.show = true;
      } else {
        // 删除
        this.$confirm(`确定要删除该条专利授权吗？`, "确认删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.po.memberForAccredits.splice(index, 1);
          this.$message.success("删除成功");
        });
      }
    },
    // 提交专利授权
    work3Submit() {
      this.$refs.work3Form.validate((bool) => {
        if (bool) {
          if (this.work3.setIndex !== -1) {
            this.po.memberForAccredits[this.work3.setIndex].category =
              this.work3.formData.category;
            this.po.memberForAccredits[this.work3.setIndex].patentName =
              this.work3.formData.patentName;
            this.po.memberForAccredits[this.work3.setIndex].patentNo =
              this.work3.formData.patentNo;
            this.po.memberForAccredits[this.work3.setIndex].accreditDate =
              this.work3.formData.accreditDate;
            this.po.memberForAccredits[this.work3.setIndex].ranking =
              this.work3.formData.ranking;
          } else {
            this.po.memberForAccredits.push({
              category: this.work3.formData.category,
              patentName: this.work3.formData.patentName,
              patentNo: this.work3.formData.patentNo,
              accreditDate: this.work3.formData.accreditDate,
              ranking: this.work3.formData.ranking,
            });
          }
          this.work3Clear();
        }
      });
    },
    // 关闭专利授权弹窗
    work3Clear() {
      this.work3.formData = {
        category: "",
        patentName: "",
        patentNo: "",
        accreditDate: "",
        ranking: "",
      };
      this.work3.setIndex = -1;
      this.work3.show = false;
    },
    // 打开专奖励、荣誉弹窗
    work4DialogShow() {
      if (this.po.memberForRewards.length >= 3) {
        this.$message.warning("最多填写三条数据");
      } else {
        this.work4.show = true;
      }
    },
    // 点击奖励、荣誉item
    work4ItemClick(flag, index) {
      if (flag == 1) {
        // 编辑
        const obj = this.po.memberForRewards[index];
        this.work4.formData.rewardName = obj.rewardName;
        this.work4.formData.time = obj.time;
        this.work4.formData.prizeUnit = obj.prizeUnit;
        this.work4.formData.ranking = obj.ranking;
        this.work4.setIndex = index;
        this.work4.show = true;
      } else {
        // 删除
        this.$confirm(`确定要删除该条奖励、荣誉吗？`, "确认删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.po.memberForRewards.splice(index, 1);
          this.$message.success("删除成功");
        });
      }
    },
    // 提交奖励、荣誉
    work4Submit() {
      this.$refs.work4Form.validate((bool) => {
        if (bool) {
          if (this.work4.setIndex !== -1) {
            this.po.memberForRewards[this.work4.setIndex].rewardName =
              this.work4.formData.rewardName;
            this.po.memberForRewards[this.work4.setIndex].time =
              this.work4.formData.time;
            this.po.memberForRewards[this.work4.setIndex].prizeUnit =
              this.work4.formData.prizeUnit;
            this.po.memberForRewards[this.work4.setIndex].ranking =
              this.work4.formData.ranking;
          } else {
            this.po.memberForRewards.push({
              rewardName: this.work4.formData.rewardName,
              time: this.work4.formData.time,
              prizeUnit: this.work4.formData.prizeUnit,
              ranking: this.work4.formData.ranking,
            });
          }
          this.work4Clear();
        }
      });
    },
    // 关闭奖励、荣誉弹窗
    work4Clear() {
      this.work4.formData = {
        rewardName: "",
        time: "",
        prizeUnit: "",
        ranking: "",
      };
      this.work4.setIndex = -1;
      this.work4.show = false;
    },
    // 获取用户信息
    getUserInfoFun() {
      const that = this;
      if (!IndexStore.userInfo) {
        setTimeout(() => {
          that.getUserInfoFun();
        }, 1000);
      } else {
        this.userInfo = IndexStore.userInfo;
        this.po.idCard = IndexStore.userInfo.idCard
      }
    },
    // 获取所有分支机构
    getInstitutionList() {
      this.pageLoading = true;
      GetInstitutionAllList().then((res) => {
        this.pageLoading = false;
        if (res.code === "000") {
          this.instList = res.result;
        }
      });
    },
    // 获取申请表状态
    getState() {
      this.pageLoading = true;
      GetApplyState().then((res) => {
        this.pageLoading = false;
        if (res.code === "000") {
          if (res.result) {
            this.id = res.result.id;
            this.remarks = res.result.remarks;
            this.state = res.result.state;
            if (res.result.state === 6) {
              this.formShow = true;
              console.log('重新填写')

              this.getDetail(res.result.id);
            } else {
              this.formShow = false;
              this.getRecordList();
            }
          } else {
            this.state = 99;
          }
          if (res.result && res.result.state === 3) {
            this.id = ''
          }
        }
      });
    },
    // 点击重新修改或重新填写
    again(flag) {
      // 1 退回修改  2 重新填写
      console.log('退回修改')
      this.formShow = true;
      if (flag === 1) {
        this.getDetail(this.id);
      }
    },
    // 获取申请表详情
    getDetail(id) {
      this.pageLoading = true;
      GetApplyDetail({ id }).then((res) => {
        this.pageLoading = false;
        if (res.code === "000") {
          const data = res.result;
          let imgArr = [];
          if(data.pictureInfo) {
            data.pictureInfo.forEach((val, ind) => {
              imgArr.push(val.path);
            });
            this.uploadImageConfig.callback = imgArr.join(",");

          }
          if (data.idCard) {
            this.po.idCard = data.idCard
          }
          this.po.sex = data.sex;
          this.po.pictureFile = data.pictureFile;
          this.po.birthday = data.birthday;
          this.po.fromRemarks = data.fromRemarks;
          this.po.national = data.national;
          this.po.politicalLandscape = data.politicalLandscape;
          this.po.learningExperience = data.learningExperience;
          this.po.technicalTitles = data.technicalTitles;
          this.po.professional = data.professional;
          this.po.workingState = data.workingState;
          this.po.email = data.email;
          this.po.nationality = data.nationality;
          this.po.nativePlace = data.nativePlace;
          this.po.schoolRecord = data.schoolRecord;
          this.po.graduateSchool = data.graduateSchool;
          this.po.mailingAddress = data.mailingAddress;
          this.po.zipCode = data.zipCode;
          this.po.isAcademician = data.isAcademician;
          this.po.workResult = data.workResult;
          this.po.bookProveFiles = data.bookProveFiles;
          this.po.patentProveFiles = data.patentProveFiles;
          this.po.paperProveFiles = data.paperProveFiles;
          this.po.resultProveFiles = data.resultProveFiles;
          this.po.rewardProveFiles = data.rewardProveFiles;
          this.po.pdfFile = data.pdfFile;
          this.uploadFileConfig1.callback = data.resultProveInfos;
          this.uploadFileConfig6.callback = data.pdfInfo;

          this.po.idCardFr = data.idCardFr;
          this.po.idCardBk = data.idCardBk;
          this.po.degreeFile = data.degreeFile;
          this.uploadFileConfig7.callback = data.idCardFrs;
          this.uploadFileConfig8.callback = data.idCardBks;
          this.uploadFileConfig9.callback = data.degreeFiles;
          if (data.paperProveInfos) {
            this.uploadFileConfig2.callback = data.paperProveInfos;
          }
          if (data.bookProveInfos) {
            this.uploadFileConfig3.callback = data.bookProveInfos;
          }
          if (data.patentProveInfos) {
            this.uploadFileConfig4.callback = data.patentProveInfos;
          }
          if (data.rewardProveInfos) {
            this.uploadFileConfig5.callback = data.rewardProveInfos;
          }
          this.po.memberForResumes = data.memberForResumes;
          this.po.memberForPapers = data.memberForPapers;
          this.po.memberForBooks = data.memberForBooks;
          this.po.memberForAccredits = data.memberForAccredits;
          this.po.memberForRewards = data.memberForRewards;
          let instId = "";
          const instArr = [];
          data.memberInstitutions.forEach((val, ind) => {
            if (val.type === "1") {
              instId = val.institutionsId;
            } else {
              instArr.push(val.institutionsId);
            }
          });
          this.po.instId = instId;
          this.instIdChange(instId);
          this.setInstArr(instArr);
          // this.po.instArr = instArr;
        }
      });
    },
    setInstArr(arr) {
      const that = this;
      setTimeout(() => {
        that.po.instArr = arr;
      }, 1000);
    },
    // 获取申请记录历史表
    getRecordList() {
      GetApplyRecordList({
        pageNo: 1,
        pageSize: 10000,
      }).then((res) => {
        if (res.code === "000") {
          this.recordList = res.result.records;
        }
      });
    }


  }

}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.title {
  text-align: center;
  font-size: 20px;
  padding: 30px 0 10px 0;
}

.form-box {
  padding: 50px 100px;
}

.form-item-from-box {
  position: relative;
  padding-bottom: 20px;
}

.add-btn {
  position: absolute;
  top: 10px;
  right: 50px;
}

.state-title {
  padding-top: 10px;
  text-align: center;
}

.record-box {
  width: 800px;
  margin: auto;
}

.record-list {
  max-height: 600px;
  overflow-y: auto;
}

.form-apply {
  width: 1200px;
  margin: auto;
}
</style>
